/**
* Owl Carousel v2.2.0
* Copyright 2013-2016 David Deutsch
* Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
*/
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;

  @include media-breakpoint-up(sm)
  {
    &:hover {
      .owl-single-item-nav {
        opacity: 0.3;
      }
    }
  }
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  width: 99%;
  -webkit-transform-style: preserve-3d;
  object-fit: contain;
  display: inline-block;
  vertical-align: middle;
}
#single-carousel .owl-item img {
  height: 300px;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-single-item-nav.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-prev {
  position: absolute;
  transform: translateY(-50%);

}

.owl-next {
  position: absolute;
  right: 0;
  transform: translateY(-50%);
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease, transform .4s cubic-bezier(0, 0, .26, 1), opacity .3s cubic-bezier(0, 0, .26, 1), -webkit-transform .3s cubic-bezier(0, 0, .26, 1);
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
* Owl Carousel v2.2.0
* Copyright 2013-2016 David Deutsch
* Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
*/
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  -webkit-tap-highlight-color: transparent;

  position: absolute;
  top: 50%;
  width: 100%;
}

.owl-theme .owl-nav [class*='owl-'] {
  font-size: 22px;
  margin: 5px;
  padding: 4px 7px;
  background: rgba(255, 255, 255, .75);
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  transition: all 300ms ease;
  opacity: 0;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;

}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

/**
Custom classes
**/

.owl-single-item-nav {
  cursor: pointer;
  opacity: 0;
  transition: opacity 300ms;
  top: 40%;
  bottom: 40%;
  max-width: 45px;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  &:hover {
    opacity: .5 !important;
  }

  .owl-single-item-control {
    margin: auto;
    position: absolute;
    top: 50%;
    transform:translateY(-36%);
  }

}

.owl-carousel .disabled {
  display: none;
}

.disabled {
  .owl-single-item-nav {
    display: none;
  }
}

.owl-single-item {
  .owl-dots {
    display: none;
  }
}

.owl-thumbs {display:none;}

.owl-carousel {
  .carousel-control {
    background-image: none !important;
    opacity: 1;
    display: block;
    text-align:left;
    &.right {
      text-align:right;
      right: 0;
      .owl-single-item-control {
        right: 0;
      }
    }
    &:hover {
      opacity: 1 !important;
      i {color:$primary;}
    }
  }
  .owl-single-item-control {
    color: $gray-dark;
    text-shadow: none;
    font-size: 3rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
      padding: 0 0.5rem;
    }
  }
  .owl-dots {
    display: block;
  }
}
#thumb-carousel {
  .carousel-control {
   width:5%;
  }
}

@include media-breakpoint-up(sm) {
  #single-carousel .owl-item img {
    height: 500px;
  }
}
.owl-thumbs {
  display: inline-block;
  .owl-thumb {
    object-fit: contain;
    width: 8em;
    height: 8em;
    display: inline-block;
    vertical-align: middle;
    background:$white;
    border:1px solid transparent;
    margin:.5rem .5rem 0 0;
    &.active,
    &:hover {
      border:1px solid $primary;
      cursor: pointer;
    }

    @include media-breakpoint-down(md) {
      height: 4em;
    }
  }
}
@include media-breakpoint-up(lg) {
  #single-carousel .owl-item img {
    height: 600px;
  }
}
@include media-breakpoint-up(xl) {
  #single-carousel .owl-item img {
    height: 700px;
  }
}

/* Compatibility for IE */
html.ie {
  // set width to maximal available size
  #single-carousel .owl-item img {
    width: auto;
    display: inline-block;
  }
  // align image center
  #single-carousel .owl-item > div {
    text-align: center;
  }

  // prevent wrap
  #thumb-carousel .owl-stage {
    display: flex;
    
    // thumb-image takes assigned height of parent and displays optimal width
    .owl-item {
      width: auto !important;

      img {
        width: auto;
        margin-right: 0 !important;
      }
    }
  }
}


