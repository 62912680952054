.notification-wrapper
{
    position: fixed;
    right: .5rem;
    top: 10rem;

    @include media-breakpoint-down(xs) {
        width: 100%;
        padding: 0 ($grid-gutter-width / 2);
        right: auto;
    }
}