.cmp-paginator {
	line-height: 1;
	min-height: calc(#{$font-size-base} + #{$pagination-padding-y * 2} + #{$pagination-border-width * 2});
}

@include media-breakpoint-down(xs) {
    .history {
        .xs-center {
            float: none !important;
            text-align: center;
        }
    }
}
