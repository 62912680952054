nav.navbar
{
	.container-max
	{
		display: block;
	}
}

nav.navbar ul.nav li.dropdown:hover .dropdown-menu {
	display: block;
	margin-top: 0px;
}

.dropdown-menu.dropdown-menu-right {
	min-width: 100%;
}

.dropdown-item.special-case {
	padding: 3px 5px !important;
}

/* settings */
// mobile
$menu-h-sm  			: 100%;
$menu-w-sm  			: 100%;
$menu-bg-sm 			: $primary;
$menu-breadcrumb-bg 	: $gray-darker;
$menu-breadcrumb-color 	: $white;
$menu-color-sm 			: $white;
$menu-font-s-sm  		: 1.2em;
$menu-font-f-sm  		: $font-family-serif;

// desktop
$menu-h-lg  			: 100%;
$menu-w-lg  			: 100%;
$menu-bg-lg 			: transparent;
$menu-color-lg 			: $primary;
$menu-font-s-lg  		: 1rem;
$menu-font-f-lg  		: $font-family-serif;
$menu-li-bg-hover		: $primary;
$menu-li-color-hover	: color-yiq($primary);
$menu-li-padding-x      : 1rem;
$menu-li-padding-y      : 1.3rem;
// bigmenu
$menu-big-menu-bg 				: darken($primary,10%);
$menu-big-menu-li-bg			: transparent; //color-yiq($primary, $gray-dark, transparent);
$menu-big-menu-li-bg-hover		: $primary;
$menu-big-menu-li-color			: color-yiq($primary);
$menu-big-menu-li-color-hover	: $body-color;
// megamenu
$megamenu-bg 					: $white;
$megamenu-color-top-btn			: $primary;
$megamenu-color-bottom-btn		: $black;
$megamenu-btn-hover 			: darken($top-bar-basket-btn, 20%);
$megamenu-btn-more 				: $gray;

body.menu-is-visible { 
	overflow-y : hidden;
	position: fixed;
}
#mainNavbarCollapsable {
	background 	: $menu-bg-sm;
	bottom 		: 0;
	color 		: $menu-color-sm;
	font-size 	: $menu-font-s-sm;
	left 		: 0;
	opacity 	: .6;
	overflow-y 	: auto;
	position 	: fixed;
	right 		: 0;
	top 		: 0;
	transform 	: translateX(-100%);
	transition 	: all .3s ease;

	&.open {
		opacity 	: 1;
		transform 	: translateX(0);
	}

	ul.breadcrumb {
		background : $menu-breadcrumb-bg;
		max-width  : initial;
		font-size  : .8em;
		padding    : 0 calc(15px + .5em) 0 15px;

		li {
			display : inline-block;
			cursor 	: pointer;
			padding : .8rem 0 .75rem;
			&.btnClose {
				float 		: right;
				padding-top : .6em;
				&:before {
					content 	: "\f00d";
					font-family : "FontAwesome";
					font-size   : 1.2em;
				}
			}
			&:not(:first-of-type) a:before {
				content 		: "\f0da";
				font-family 	: FontAwesome;
				font-size 		: 1rem;
				padding 		: 0 .3rem;
			}
			&:first-of-type a:before { display : none!important; };
		}
	}

	ul.mainmenu {
		list-style 	: none;
		height 		: 100%;
		margin 		: 0;
		padding 	: 0;
		position 	: relative;

		&:empty {
			min-height: $line-height-base + $menu-li-padding-y * 2;
		}

		li 	{
			width : 100%;
			cursor: pointer;
			float: left;
			display: flex;
			flex-wrap: wrap;

			a 	{
				color 	: inherit;
				display : inline-block;
				padding : 1rem ;
				flex: 1 0 80%;
			}

			.nav-direction{
				padding: 1rem;
				flex: 1 0 20%;
				text-align: center;
			}

			ul {
				background 	: $menu-bg-sm;
				left 		: 0;
				list-style 	: none;
				margin 		: 0;
				padding 	: 0;
				position 	: relative;
				top 		: 0;
				width 		: 100%;
			}
			&.ddown {
				.nav-direction {
					i {
						transition: transform 300ms ease-out;
						transform: rotateX(0deg);
					}
				}
				.nav-direction[aria-expanded="true"] {
					i {
						transform: rotateX(180deg);
					}
				}
			}
		}
	}

	@include media-breakpoint-up(sm){

		ul.mainmenu {
			li 	{
				.nav-direction{
					padding-right: calc(4px + .5em);
				}
			}
		}
	}

	@include media-breakpoint-up(lg){
		background-color : $menu-bg-lg;
		display 		 : block!important;
		opacity 		 : 1;
		overflow 		 : visible;
		position 		 : relative;
		transform 		 : translateX(0);
		width 			 : 100%;
		font-size 		 : $menu-font-s-lg;
		transition 		 : all .1s ease;

		.breadcrumb { display : none!important; }
		ul.mainmenu {
			color : $menu-color-lg;
			text-align: right;
			.open>ul { display : none!important; }
			>li {
				display : inline-block;
				width 	: auto;
				position: relative;
				transition: all 300ms;
				>a {
					padding: $menu-li-padding-y $menu-li-padding-x;
					width:100%;
				}
				>ul {
					display 		: block;
					visibility 		: hidden;
					opacity 		: 0;
					transition 		: all .1s ease;
					background 		: $menu-big-menu-bg;
					height 			: auto;
					top 			: 100%;
					text-align 		: center;
					width 			: auto;
					max-width 		: 25em;
					min-width 		: 100%;
					word-wrap		: break-word;
					position 		: absolute;

					>li {
						background 	: $menu-big-menu-li-bg;
						color 		: $menu-big-menu-li-color;
						transition  : all .1s ease;
						width 		: 100%;
						min-width	: 150px;
						text-align  : initial;

						.no-touch &:hover,
						.touch &.hover {
							background  	: $menu-big-menu-li-bg-hover;
							color 			: $menu-big-menu-li-color;
							text-decoration : none;
						}

						.megamenu &:hover {
							background: none;
						}

						>a {
							width:100%;
						}
					}
				}

				.no-touch &:hover,
				.touch &.hover {
					background-color : $menu-li-bg-hover;
					color 			 : $menu-li-color-hover;
					transition 		 : all .1s ease;
					>ul {
						visibility 		: visible;
						opacity 		: 1;
						transition 		: all .1s ease;
					}

				}
				&.ddown>a { transition: all 0s; }
				&.ddown>a:after {
					content : '\f0d7';
					font-size: inherit;
					padding-left:.5em;
					border 			: none;
					float 			: right;
					font-family 	: FontAwesome;
					height 			: auto;
					width 			: auto;
				}
				&.ddown .ddown a:after { display : none; }
			}
		}
	}
}

@include media-breakpoint-up(lg){ 
	nav.navbar.megamenu {
		position : relative;

		#mainNavbarCollapsable ul.mainmenu {
			background 		: transparent;
			display 		: flex;
			justify-content : flex-end; 
			width 			: 100%; 

			>li {
				position : static;

				.no-touch &:hover,
				.touch &.hover {
					background : transparent !important; 
					color 	   : $megamenu-btn-hover !important;  

					>ul.collapse { display : block; } 
				}

				>ul.collapse {
					background-color: $megamenu-bg;
					column-gap		: .5em;
					column-rule		: 1px solid #dcdbd7;
					display			: none;
					padding			: 1em 0;
					min-height		: 7em;
					max-height		: 86vh;  
					column-count	: 5;
					overflow-x		: auto;
					overflow-y		: hidden;
					column-fill		: balance;

					li {
						display		: list-item;
						float		: none;
					}

					>li ul.nav-dropdown-inner {
						background-color	: $megamenu-bg;
						padding-bottom		: 1em;

						>li {
							padding	: 0 1.5em;
							line-height		: 170%;
							text-align		: left;
		
							&.bt-more>a {
								color 			: $megamenu-btn-more;
								text-transform 	: lowercase; 
							}
							>a {
								color	: $megamenu-color-bottom-btn;
								padding	: 0;
							}
						}
						.level1 { 
							>a {								
								color		: $megamenu-color-top-btn; 
								font-weight	: bold; 
							}
						}
						.level3 { 
							padding-left	: 2.5em;
						}
					}
				}
			}
		}
	}
}

.mobile-navigation{
	background 	: $menu-bg-sm;
	bottom 		: 0;
	color 		: $menu-color-sm;
	font-size 	: $menu-font-s-sm;
	left 		: 0;
	opacity 	: .6;
	overflow-y 	: auto;
	position 	: fixed;
	right 		: 0;
	top 		: 0;
	transform   : translateX(100%);
	transition 	: all .3s ease;
	z-index     : 99999;
	-webkit-overflow-scrolling: touch;

	&.open {
		opacity 	: 1;
		transform 	: translateX(0);
	}

	ul.breadcrumb {
		background : $menu-breadcrumb-bg;
		max-width  : initial;
		font-size  : .8em;
		padding    : 0 calc(15px + .5em) 0 15px;
		display    : block;

		li {
			display : inline-block;
			cursor 	: pointer;
			padding : .8rem 0 .75rem;

			&:last-child {
				pointer-events: none;
			}

			&.btn-close {
				float 		: right;
				padding-top : .6em;
				&:before {
					content 	: "\f00d";
					font-family : "FontAwesome";
					font-size   : 1.2em;
				}
			}
			&:not(:first-of-type) a:before {
				content 		: "\f0da";
				font-family 	: FontAwesome;
				font-size 		: 1rem;
				padding 		: 0 .3rem;
			}
			&:first-of-type a:before { display : none!important; };
		}
	}

	ul.mainmenu {
		list-style 	: none;
		height 		: 90%;
		margin 		: 0;
		padding 	: 0;
		position 	: absolute;
		width: 100%;
		display: none;

		&.menu-active {
			display: block !important;
		}

		.btn-up {
			flex: none !important;
			transform: rotateY(180deg);
		}

		li 	{
			width : 100%;
			cursor: pointer;
			float: left;
			display: flex;
			flex-wrap: wrap;

			a 	{
				color 	: inherit;
				display : inline-block;
				padding : 1rem ;
				flex: 1 0 80%;

				&:hover {
					color: inherit;
				}
			}

			.nav-direction{
				padding: 1rem;
				flex: 1 0 20%;
				text-align: center;
			}

			ul {
				background 	: $menu-bg-sm;
				left 		: 0;
				list-style 	: none;
				margin 		: 0;
				padding 	: 0;
				position 	: relative;
				top 		: 0;
				width 		: 100%;
			}
			&.ddown {
				.nav-direction {
					i {
						transition: transform 300ms ease-out;
						transform: rotateX(0deg);
					}
				}
				.nav-direction[aria-expanded="true"] {
					i {
						transform: rotateX(180deg);
					}
				}
			}
		}
	}
}

.breadcrumb-item-transition {
  transition: all 1s ease;
  overflow: hidden; }

.breadcrumb-item-enter, .breadcrumb-item-leave {
  opacity: 0;
  transform: translateX(-30px); }

.mainmenu-[class^='animate-'],
.mainmenu[class*=' animate-'] {
  display: block !important;
}

.animate-outToRight .ddown {
  -webkit-animation: outToRight 0.2s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: outToRight 0.2s both cubic-bezier(0.7, 0, 0.3, 1); }

@-webkit-keyframes outToRight {
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }
@keyframes outToRight {
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }
.animate-outToLeft .ddown {
  -webkit-animation: outToLeft 0.2s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: outToLeft 0.2s both cubic-bezier(0.7, 0, 0.3, 1); }

@-webkit-keyframes outToLeft {
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }
@keyframes outToLeft {
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }
.animate-inFromLeft .ddown {
  -webkit-animation: inFromLeft 0.2s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: inFromLeft 0.2s both cubic-bezier(0.7, 0, 0.3, 1); }

@-webkit-keyframes inFromLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes inFromLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
.animate-inFromRight .ddown {
  -webkit-animation: inFromRight 0.2s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: inFromRight 0.2s both cubic-bezier(0.7, 0, 0.3, 1); }

@-webkit-keyframes inFromRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes inFromRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0); } }
	