.widget-grid
{
    > .widget-inner
    {
        > div
        {
            height: 100%;
        }
    }

    .widget-inner-stacked
    {
        display: flex;
        flex-direction: column;

        > .widget-inner
        {
            display: flex;

            > div
            {
                flex: 1;

            }

            [data-builder-child-container]
            {
                min-height: 100px;
            }
        }
    }

    @include media-breakpoint-up(md)
    {
        .widget-inner-stacked > .widget-inner:first-child
        {
            padding-bottom: 2rem;
        }
    }
}


// a list widget in the footer needs the grey background for store feature colors
.footer {
    .widget-grid {
        .widget-inner {
            .widget-list {
                position: relative;
                height: 100%;
                color: $footer-features-color;
                background-color: $footer-features-bg;
                box-sizing: content-box;
                margin-bottom: 0;

                .widget-inner
                {
                    padding: 1rem;
                }
            }

            @include media-breakpoint-up(md)
            {
                .widget-list
                {
                    margin-left: -15px;
                    margin-right: -15px;
                    padding-left: 15px;
                    padding-right: 15px;
                }

                &:first-child {
                    .widget-list {
                        margin-left: 0;
                        padding-left: 0;
                    }
                }

                &:last-child {
                    .widget-list {
                        margin-right: 0;
                        padding-right: 0;
                    }
                }
            }


            @include media-breakpoint-down(sm) {
                &:not(:last-child) {
                    .widget-list {
                        .widget-inner {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
