.widget
{
    margin-bottom: 2rem;

    .widget-inner
    {
        position: relative;

        .widget
        {
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-down(sm)
{
    .widget
    {
        margin-bottom: 1rem;

        &.widget-grid
        {
            margin-bottom: 0;

            + .widget-grid
            {
                margin-top: 1rem;
            }
        }

        .widget-inner
        {
            .widget
            {
                margin-bottom: 1rem;
            }
        }
    }
}

.widget-proportional
{
    position: relative;
    padding-bottom: percentage(1/3);
    overflow: hidden;
    
    .widget-inner
    {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@include media-breakpoint-up(md)
{
    @each $i in 1,2,3,4
    {
        @each $j in 1,2,3,4
        {
            .widget-prop-#{$i}-#{$j} .widget-proportional
            {
                padding-bottom: percentage($j / $i);
            }

            .widget-proportional.widget-prop-#{$i}-#{$j}
            {
                padding-bottom: percentage($j / $i) !important;
            }
        }

    }
    .widget-prop-auto .widget-proportional
    {
        padding-bottom: 0;
        min-height: 100%;
    }
}

.widget-editable-min-height {
    min-height: 40px;
}
