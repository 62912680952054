body .wrapper-main{
    overflow-x: hidden;
}

.hidden {
    display: none;
}

.hidden-sm {
    @include media-breakpoint-only(sm) {
        display: none;
    }
}

.page-content{
    padding-top: $spacer;
    background-color: $gray-lightest;
    &.basket,
    &.checkout,
    &.contact,
    &.myaccount {
        &:not(.checkout):not(.basket) {
            background-color: $white;
        }
        color: $gray-darker;
        margin-top: 30px;
        padding-bottom: 30px;
    }
}
.container-max{
    @extend .container-fluid;
    max-width: 1200px;
    z-index: auto;

    &.login {
        margin-top: 2rem;
    }
}

.page-not-found {
    i.fa-search {
        font-size: 10em;
    }
    .default-not-found {
        background-color: rgb(247, 247, 249);
    }
    .not-found-text {
        font-size: 115px;
        text-shadow: 4px 3px 0 #F7F7F9, 9px 8px 0 rgba(218, 218, 227, 0.50);
    }
}

.rating{
    line-height: 1;

    .tag,
    .badge {
        padding-right: 0;
        overflow: hidden;
        cursor: pointer;

        .amount{
            padding: 0.1rem .6rem 0.1rem .4rem;
            margin-left: .2rem;
            background-color: $gray;
        }
    }
}

dt {
    font-weight: 400;
}

a:hover,
a:focus,
a:active {
    text-decoration: none;
}

.border-left {
    border-left: 1px solid $gray-dark;
}


.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
}
.carousel-control-prev {
    left: 0;
}
.carousel-control-next {
    right: 0;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
}

.pagination > li {
    cursor: pointer;
}

.content-center {
    margin: 0 auto;
    float: none;
}

.bg-white {
    background-color: $white !important;
}

.text-wrap {
  white-space: normal;
}
