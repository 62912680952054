.featured-category{
	display: block;
	height: 100%;
	overflow: hidden;
	color: $white;
	text-align: center;
	font-family: $font-family-heading;

	@include img-cover();

	.featured-category-inner{
		position: absolute;
		width: auto;
		bottom: 0;
		left: 0;
		z-index: 100;

	    @include clearfix();
	    padding: .5rem 1rem;
    	background: transparent;
	}

	.featured-category-headline{
		font-weight: normal;
		display: block;
		padding: 1rem;
	    box-shadow: none;
	    align-self: flex-start;
	    font-size: 1.25rem;
	    margin: 1rem;
	    width: auto;
	    text-align: left;
	    transition: all .3s ease;
		@include context-bg(rgba($primary, .8));
	}

	.featured-category-cta{
		display: block;
		
		border-top: 1px solid $gray-lighter;
		max-height: 0;
		height: auto;
		transition: all 300ms;
		
		span{
			display: block;
			padding: 1rem;
		}

		.fa{
			margin-left: .5rem;
		}
	}

	&:hover {
		.featured-category-headline{
		    background: rgba($primary,1);
		    transition: all .3s ease;
		}
	}

	@include media-breakpoint-up(sm){
		
	}

	@include media-breakpoint-up(md){
		.featured-category-headline{
			font-size: 1.75rem;
			padding: 1.5rem;
		}

		&:hover{
			.featured-category-cta{
				max-height: 5rem;
			}
		}
	}

	@include media-breakpoint-up(lg){
		
	}

}