.login-pwd-reset{

  .login-container{

    .send-login-btn {
      display: block;
      margin-top: 10px;
      width: 100%;

      i{
        margin-right: 5px;
      }
    }

    @include media-breakpoint-up(sm){

      .send-login-btn {
        display: inline-block;
        margin-top: auto;
        width: auto;
      }
    }
  }

  .reset-pwd-container {
    display: none;

    .cancel-reset-pwd-btn {
      display: block;
      outline: none;
      margin-bottom: 10px;
    }

    .send-reset-pwd-btn {
      display: block;
      outline: none;

      i {
        vertical-align: middle;
        margin-right: 5px;
      }
    }

    @include media-breakpoint-up(sm) {

      .cancel-reset-pwd-btn {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: auto;
      }

      .send-reset-pwd-btn {
        display: inline-block;
      }
    }
  }

  .input-unit{

       &+.error-msg{
         display: none;
         font-size: 12px;
         color: #d9534f;
         margin-bottom: 10px;
       }

    &.has-login-error{
      z-index: 1000;
      border-color: #d9534f;

      input{
        color: #d9534f;
      }

      label{
        color: #d9534f;
      }
    }

    &.error{
      margin-bottom: 2px;

      &+.error-msg{
        display: block;
      }
    }
  }
}