html {
	font-size: $font-size-root;
}

body {

	background-color: $gray-lightest;

	> #vue-app,
	> #vue-error {

		background-color: $gray-lightest;
		
		&>.wrapper-top, &>.wrapper-left, &>.wrapper-right, &>.wrapper-bottom {
			position 	: absolute;
			z-index 	: $zIndex-8;

			.wrapper-inner{
				
			}
		} 

		.wrapper-main{
			position: relative;
			//z-index: $zIndex-1;
		}
		
		.wrapper-top {
			top 		: 0;
			right 		: 0;  
			left 		: 0;
		}
		
		.wrapper-right {
			position	:fixed;
			top 	 	: 0;
			bottom 		: 0;
			left 		: 100%;
			width 		: 0;

			overflow 	: hidden;
			
			background-color : white;
			transform: translateX(0);

			transition: width 310ms 0ms,
						transform 300ms ease-in-out;

			.wrapper-inner{
				width: 100%;
				height: 100%;
			}
		}
		
		.wrapper-bottom {
			z-index 	: 2000;
		}
		
		.wrapper-left {
			top 		: 0;
			bottom 		: 0; 
			left 		: 0;
			transform 	: translateX(-100%);
		}
		
		// body state modifiers
		&.overlay,
		&.open-right,
		&.open-left,
		&.open-top,
		&.open-bottom{

			@include media-breakpoint-down(sm)
			{
				margin-top: 0 !important;
			}
			
			// dark overlay on body
			&::after{
				content : "";
				position: fixed;
				top 	: 0;
				right 	: 0;
				bottom 	: 0;
				left 	: 0;

				z-index: $zIndex-7;

				background-color: rgba(0, 0, 0, .5);
			}
		}
		

		&.open-right{
			overflow: hidden;

			@include media-breakpoint-down(sm) {
				position: fixed;
			}

			.wrapper-right{
				@include media-breakpoint-up(md) {
					width: 100vw;
				}
				
				transform: translateX(-100%);

				transition: width 0ms,
							transform 300ms 10ms ease-in-out;

				@include media-breakpoint-down(md) {
					width: 100%;
				}
			}
		}

		&.open-hover {
			@include media-breakpoint-down(xs) {
				position: fixed;
			}

			.basket-preview-hover-wrapper {
				display: block;
				transform: translateX(0%);
			}
		}

		@include media-breakpoint-up(sm){
		}

		@include media-breakpoint-up(md){
			&.open-right{
				.wrapper-right{
					width: $aside-columns-width;
				}
			}
		}

		@include media-breakpoint-up(lg){
			
		}
	}
}