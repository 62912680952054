.cmp-method-list{

	.method-list{
		list-style-type: none;
		margin: 0;
		padding: 0;
		width: 100%;
	}

	.method-list-item{
		width: 100%;
		display: inline-block;
		vertical-align:top;
		margin-top: -1px;
		margin-left: -1px;
		background-color: $card-bg;

		& > input:disabled ~ label {
			opacity: .5;
			cursor: not-allowed;
		}

		input{
			display: none;
			transition: box-shadow 2s;
		}

		input:checked + label{
			box-shadow: inset 0 0 0 .1rem $primary;
		}

		label{
			border: 1px solid $gray-lighter;
			border-radius: $border-radius;
			cursor: pointer;
			padding: .5em;
			margin-bottom: 0;
			width:100%;
			overflow: hidden;
		}

		.content,
		.icon{
			display: inline-block;
			vertical-align: middle;
		}

		.icon{
			width: 3.5rem;
			height: 3.5rem;

			img{
				width: 100%;
				height: auto;
			}
		}

		.content{
			width: calc( 100% - 3.5rem );
			padding-left: .5rem;
		}
        }
        &.list {
		.method-list-item:not(:last-child){
			margin-bottom: 1em;
		}
		.content{
			width: calc( 100% - 4rem );
			padding-left: .5rem;
			position: relative;
		}
		.icon{
			width: 4rem;
			height: 4rem;
		}
	}

	@include media-breakpoint-up(sm){

	}

	@include media-breakpoint-up(md){
		.method-list{
			display: flex;
			flex-flow: row wrap;
		}
		.method-list-item{
			width: 50%;
			display: flex;
			flex-flow: row nowrap;
		}
		&.list {
			.method-list-item{
				width: 100%;
			}
		}
	}

	@include media-breakpoint-up(lg){

	}
}
