.widget-text
{
    .widget-inner
    {
        padding: 1rem 1.5rem;
    }

    .placeholder-text
    {
        border: 3px dashed #dadae3;
        border-radius: 3px;
        height: 75px;
        line-height: 75px;
        vertical-align: middle;
        text-align: center;
        color: #dadae3;
        font-size: 20px;
        font-style: italic;
    }

    &.widget-primary, &.widget-secondary, &.widget-success, &.widget-info, &.widget-warning, &.widget-danger, &.widget-inverse
    {
        .placeholder-text
        {
            border-color: white;
            color: white;
        }
    }

    &.widget-primary
    {
        @include widget-bg-variant($primary);
    }

    &.widget-secondary
    {
        @include widget-bg-variant($secondary);
    }

    &.widget-success
    {
        @include widget-bg-variant($success);
    }

    &.widget-info
    {
        @include widget-bg-variant($info);
    }

    &.widget-warning
    {
        @include widget-bg-variant($warning);
    }

    &.widget-danger
    {
        @include widget-bg-variant($danger);
    }
}