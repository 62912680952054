.cmp-country-settings,
.cmp-currency-select {
	background-color: $gray-darker;
	color: $white;
	border-bottom: 0;

	z-index: 1070;
    position: fixed;
    top: 30px;
    width: 100%;
    left: 50%;
	transform: translateX(-50%);

	&.collapsing {
		overflow: hidden;
	}

	@include media-breakpoint-up(lg) {
		overflow: auto;
		max-height: 90vh;
	}

	@include media-breakpoint-down(sm) {
		overflow: auto;
		max-height: 60vh;
	}

	.shipping-settings, 
	.language-settings{
		width: 100%;

		& ul {
			@include media-breakpoint-down(sm) {
				column-count: 2;
			}
		}
	}

	ul{
		@include reset-list();

		@include media-breakpoint-up(md) {
			overflow: auto;
			max-height: 40vh;
		}

		li{
			width: 100%;
			padding: 0;
			display: inline-block;
			float: left;

			a{
				display: inline-block;
				padding: .4rem;
				color: $white;
				&:hover {
					@include context-bg($primary);
				}

				&.is-disabled{
					cursor: not-allowed;

					&:hover {
						@include context-bg($gray-dark);
					}
				}
			}

			&.active{
				a{
					@include context-bg($primary);

					&.is-disabled{
						@include context-bg($gray-dark);
					}
				}
			}
		}
	}

	@include media-breakpoint-up(sm){

	} 

	@include media-breakpoint-up(md){
		ul{
			li{
				width: 50%;
				padding-right: .8rem;
			}
		}
	} 

	@include media-breakpoint-up(lg){
		ul{
			li{
				width: 33.33%;
			}
		}
	}
	
	.currency-list {
		ul > li {
			width: 16.666667%;
		}
	}
}

.flag-icon-en {
	background-image: url(../images/flags/4x3/gb.svg);
}

.flag-icon-da {
	background-image: url(../images/flags/4x3/dk.svg);
}

.flag-icon-nn {
	background-image: url(../images/flags/4x3/no.svg);
}

.fixed-top .cmp-country-settings,
.fixed-top .cmp-currency-select
{
	top: auto;
}

.wrapper-main header
{
	.cmp-country-settings,
	.cmp-currency-select
	{
		.container-max > .row
		{
			flex-direction: row;
		}
	}
}