//header scss
.unfixed
{
	left: 0;
	right: 0;
}

.top-bar{
	@media print {
		display: none;
	}

	width: 100%;
	z-index: $zIndex-6;
	color: $top-bar-color;
	background-color: $top-bar-bg;

	a:not(.btn) {
		text-decoration: none;
		color: $top-bar-color;
		outline: none;
	}

	.search-shown {
		display: flex;
		flex: 1;

		@include media-breakpoint-up(md) {
			justify-content: space-between;
			align-items: center;

			.controls {
				.anicon,
				a:not(.btn) {
					padding: 1rem .8rem;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column-reverse;
		}

		.always-visible-search {
			margin: 5px;
			background-color: $white;
			flex: 1;
			
			.search-box-shadow-frame {
				display: flex;
				flex: 100;
	
				.search-input {
					border: none;
					outline: none;
					padding: 0.4rem 1rem;
					font-size: 1.3rem;
					line-height: 1;
					flex: 1 70%;
				}
		
				.search-submit {
					color: $primary;
					background-color: $gray-200;
					border: none;
					font-size: 1.3rem;
					line-height: 1;
					padding: 0.4rem 1rem;
					cursor: pointer;
				}
			}
		}
	}

	&.fixed-top {
		position: fixed;
	}

	.controls-list{
		width: 100%;
		margin-bottom: 0;
		font-size: 0;
		display: flex;

		&>li{
			// default font size for all items
			font-size: 1rem;
			color: $gray-lighter;
			margin-right: 0;

			a:not(.btn) {
				padding: 0.5rem .8rem;
				display: inline-block;
				color: $gray-lighter;

				&:hover {
					@include context-bg($primary);
				}
			}

			// icons should be slightly larger
			.fa:not(.qty-sign){
				font-size: 1.2rem;
			}

			.basket-seperator{
				margin: 0 2px;
			}
		}

		.control-user{
			flex: 1 0 auto;
			//login | register
			#login-change {
				position:relative;

				& > .dropdown {

					& .dropdown-toggle {
						transition: all .1s;
	
						&:after {
							margin-left: 7px;
						}
					}
	
					&.show {
						& > .dropdown-toggle {
							@include context-bg($gray-darker);
						}
	
						& .dropdown-menu {
							max-height: 200px;

							@include media-breakpoint-down(md) {
								width: 105px;

								&.dropdown-menu-right {
									right: initial;
								}
							}
						}
					}
	
					.dropdown-menu {
						z-index: 2000;
						transition: all 0.3s;
						max-height: 0;
						display: block;
						overflow: hidden;
						border: none !important;

						@include media-breakpoint-down(md) {
							width: 105px;

							&.dropdown-menu-right {
								right: initial;
							}
						}

						a {
							padding:1rem .8rem;
							border:0;
							border-top: 1px solid $gray-lighter;
							display: block;
							background-color: $gray-darker;
							color: color-yiq($gray-darker);
							&:hover {
								color: color-yiq($primary);
								background-color: $primary;
							}
	
							& > .fa-user {
								margin-right: 5px;
							}
	
							& > .fa-sign-out {
								margin-right: 3px;
							}
						}
					}
				}
			}
			.account-menu {
				padding:0;
				margin:0;
			}
		}

		.control-wish-list {
			a {
				.badge-right{
					margin-right: .5rem; 
				}
				i{
					&.fa-heart-o{
						font-size: .9rem;
					}

					&.fa-heart{
						margin-right: .2rem;
						font-size: .8rem;
						color: rgba(255, 0, 0, 0.67);
					}
				}
			}
		}

		.control-languages a[aria-expanded="true"],
		.control-search a[aria-expanded="true"] {
			background:$gray-darker;

			& > i.fa.fa-search {
				display: none;
			}

			& > i.fa.fa-close {
				display: inherit;
			}
		}

		.control-search a {

			& > i {
				min-width: 16px;
			}
			
			& > i.fa.fa-search {
				display: inherit;
			}

			& > i.fa.fa-close {
				display: none;
			}
		}

		.control-basket{
			position: relative;

			.toggle-basket-preview{
				background-color: $top-bar-basket-btn;
				color: $top-bar-basket-color;

				.badge{
					font-size: inherit;
					padding: 0;
					line-height: inherit;
					font-weight: inherit;
					margin-left: .5rem;
				}

				.badge-right{
					margin-right: .5rem; 
				}

				&:hover{
					background-color: darken($top-bar-basket-btn, 10%);
				}
			}
		}
	}
}

//navigation scss
.wrapper-main {
	#page-body {

		@media print {
			margin-top: 0;
		}
	}

	#page-header {
		background-color:$navbar-background-color;
		box-shadow: 0 0 7px 0 rgba(0,0,0,.5);
		backface-visibility: hidden;

		&.top-bar {
			background-color: $top-bar-bg;
		}

		@include media-breakpoint-down(md){
			.container-max {
				padding: 0;
			}
		}
	}

	@include media-breakpoint-up(md){
		#page-header {
			box-shadow: none;
		}
	}

	.navbar{
		font-family:$font-family-heading;
		border-bottom:1px solid darken($gray-lighter2, 2%);
		a {
			color: $gray-dark;
			&:hover {
				color: $gray-darker;
			}
		}
		.navbar-nav {
			.nav-item {
				& > .nav-link {
					min-width: 140px;
					border-right:1px solid $gray-lighter;
					text-align: center;
				}
				&:last-child {
					.nav-link {
						border-right:0;
					}
				}
				.nav-link.dropdown-toggle::after {
					display: none;
				}
				&:hover {
					.nav-link {
						background:transparent;
						color: $gray-darker;
						cursor: pointer;
						&.dropdown-toggle::after {
							display: none;
						}
					}
					.dropdown-menu {
						background:$primary;
						border:0;
						border-left:0;
						border-right:0;
						border-bottom:0;
						text-align: center;
						&::before {
							display: inline-block;
						    width: 0;
						    height: 0;
						    content: "";
						    border-bottom: .5em solid;
						    border-right: .5em solid transparent;
						    border-left: .5em solid transparent;
						    color: $primary;
						    position: absolute;
						    top: -.5em;
						    left: 50%;
						    transform: translateX(-50%);
						}
						.dropdown-item {
							color: color-yiq($primary);
							font-size: .9rem;
							padding: .75rem 1rem !important;
							&:hover {
								background: darken($primary, 10%);
								color: color-yiq($primary);
							}
						}
					}
				}
			}
		}
	}
}



//navigation scss

.navbar {
	padding:0;
	z-index: $zindex-navbar;
	background-color: $navbar-background-color;
	border-bottom: 1px solid $gray-lighter;

	.navbar {

		border-radius: 0;
		padding:0;
		z-index: $zindex-navbar;
		border-bottom: 1px solid $gray-lighter;

		.dropdown:hover .dropdown-menu {
			display: block !important;
			margin-top: 0;
		}
	}
}

.brand-wrapper{
	position: relative;
	padding:0 15px;
	display: block;
	width: 100%;
}

.navbar-brand {
	float: none;
	padding: .5rem 0;
  	display: inline-block;

  	img {
    	max-height: 3rem;
  	}
}

.navbar-nav {

	.nav-item{
		margin: 0!important;

		&.open {
			position: relative;
		}

		&.active,
		&:hover {
			.nav-link {
				background-color: $nav-link-hover-bg;
				color: $navbar-dark-active-color;
			}
		}

		.nav-link {
			padding: 1rem;
		}
	}
}

.navbar-toggler {
	position: absolute;
	top: 50%;
	right: 1rem;
	transform: translate(0, -50%);
}

.wrapper-main header
{
	.container-max > .row
	{
		flex-direction: row-reverse;
		position: relative;
		margin-left: 0;
		margin-right: 0;
	}
	.controls-list
	{
		width: auto;

		&>li
		{
			// default font size for all items
			font-size: .8rem;

			.fa:not(.qty-sign)
			{
				// icons should be slightly larger
				font-size: 1rem;
				line-height: .8rem;
			}
		}
	}
}

@include media-breakpoint-only(xs) {
	.top-bar .controls-list {
		> li .anicon,
		> li a:not(.btn) {
			padding: .5rem;
		}
		.pipe {
			margin: 0 0.3em 0 0;
		}
		.anicon-search-xcross {
			width: 2.2rem;
		}
		.badge-right {
			display: none;
		}
	}
}

@include media-breakpoint-down(md) {
	.navbar {
		.navbar-nav{

			.dropdown-menu{
				position:relative;
				float: none;

				background-color: $gray-lighter2;
				border: none;
			}
		}
	}
}

@include media-breakpoint-up(md)
{

	.wrapper-main .navbar .navbar-nav .nav-item:hover .dropdown-menu {
		text-align: left;
	}
	
	.sticky {
		.header {
			.navbar {
				max-width: 690px;
				.navbar-brand {
					img {
						max-width: 70%;
					}
				}
				.navbar-toggler {
					top: .75rem;
				}
			}
		}
	}

	.top-bar {
		.list-inline {
			.list-inline-item {
				#login-change {
					a {
						span {
							display: inline-block;
						}
					}
				}
			}
		}
	}

	.header {
		padding-left: 15px;
		padding-right: 15px;

		.navbar {
			.navbar-brand {
				img {
					max-width: 100%;
				}
			}
			.navbar-toggler {
				top: 1.5rem;
			}
		}
	}
}

@include media-breakpoint-up(lg){

	.brand-wrapper{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index:1000;
		width: auto;
		left: 0;
	}

	.navbar-nav{
		&>.nav-item{
			&>.nav-link{
				padding: $nav-link-padding-y $nav-link-padding-x;
			}
		}
	}
}

@include media-breakpoint-up(xl){
	.brand-wrapper{
		padding:0;
	}
 	.sticky {
 		.header {
 			.navbar {
 				max-width: 1110px;
 			}
 		}
 	}
 }

.account-menu.dropdown-menu.dropdown-menu-right.small{
	min-width: 100%;
}
