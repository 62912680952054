.widget.accordion {
    div.card {
        &:first-of-type {
            border-bottom: $card-border-width solid $card-border-color;
        }
        &:nth-of-type(2) {
            border-top: 0;
        }

        button.collapsed {
            h5 {
                > .fa {
                    transform: scaleY(1);
                }
            }
        }
        button {
            text-align: left;
            padding: $card-spacer-x;
            border-top-width: 0;

            h5 {
                font-weight: bold;
                position: relative;

                > .fa {
                    position: absolute;
                    right: 0;
                    top: -4px;
                    font-size: 1.5rem;
                    transform: scaleY(-1);
                    transition-timing-function: ease;
                    transition-duration: .35s;
                    transform-origin: 50% 60%;
                }
            }
        }        
        button:hover,
        button:focus { 
            color: black;
        }

        div .card-body {
            margin-top: 1px;

            p {
                margin: 0;
                line-height: $headings-line-height;
            }
        }
    }

    @each $color, $value in $theme-colors {
        &.widget-#{$color} {
            button:hover,
            button:focus {
                text-decoration-color: $value;
            }
            h5 {
                color: $value;
            }
        }
    }
}

