@include media-breakpoint-down(xs)
{
    .widget-live-shopping
    {
        .thumb-inner
        {
            .thumb-image
            {
                float: none;
                width: 100%;

                > .square-container
                {
                    padding-bottom: 50%;
                }
            }

            .live-shopping
            {
                width: 100%;

                .live-shopping-item-name
                {
                    > a
                    {
                        font-weight: normal;
                    }
                }
            }
        }
    }   
}

.live-shopping-placeholder
{
    width: 100%;
    padding-bottom: 150%;
    position: relative;
    background-color: $gray-lighter;

    > div
    {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        padding: $spacer;
        width: 100%;
    }

    .title
    {
        font-weight: bold;
        font-size: 1.3rem;
    }

    .description
    {
        font-style: italic;
    }
}
