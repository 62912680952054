.checkout{
	.basket-items-list .item{
		padding-left: 0;
		padding-right: 0;
	}

	.login-register-title {
		font-size: 1em;
		text-transform: uppercase;
		text-align: center;
	}

	.cmp-address-list {
		transition: all .3s ease;
	}
}

.my-account-no-line {
	padding-bottom: 15px;
}

.payment-change-text {
	color: $gray-light;
	padding-top: 15px;
}

.payment-align-center {
	text-align: center;
}

.payment-change-box {
	position: relative;
	display: flex;
	flex-direction: column;
	
	@include media-breakpoint-down(sm){
		margin-top: 15px;
	}

	.payment-change-box-item:nth-child(2) {
		order: 1;
	}
	.cannot-change-payment {
		font-size: 1rem;
		padding: 5px;
		width: 100%;
		.info-badge {
			margin-left: .2rem;
		}
	}
}