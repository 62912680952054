.modal-header {
  align-items: center;
}

.modal-footer {
  >.fa {
    margin-top: 1px;
    margin-left: 5px;
  }
}
