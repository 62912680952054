.static-page-plain-text {
  white-space: pre-line;
}

@media print {
  .no-print {
    display: none;
  }

  .print-header {
    text-align: center;
    margin-top: 15px;
  }

  @page {
    size:  auto;
    margin: 0mm;
  }

  html {
    background-color: #FFFFFF; 
    margin: 0px;
  }
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}