.list-controls {
    position: relative;
    .list-controls-inner {
        background: darken($gray-lighter2, 2%);
        padding: 0.5em;
    }
    .list-sort,
    .list-itemsperpage {
        display: block;
        padding: 0.5em;
    }
    .selected-filters {
        display: inline-block;
        float: right;
        margin-top: 10px;

        .selected-filter {
            font-size: 0.8rem;
            margin: 0 0 0 10px;
            padding: 5px 10px;
            background-color: darken($gray-lighter2, 2%);
            color: $primary;
            float: left;
            cursor: pointer;
            display: inline-block;
            &:hover {
                color: $danger;
            }

            &.reset-all {
                @include context-bg($primary);

                &:hover {
                    background-color: darken($primary, 15%);
                }
            }
        }
    }

    .filter-toggle {
        text-decoration: none;

        & i {
            transition: all 0.3s ease;
        }

        &[aria-expanded="true"] {
            i {
                transform: rotate(-90deg);
            }
        }
    }

    #filterCollapse {
        position: absolute;
        z-index: 1050;
        width: 100%;
        margin-left: -15px;
        padding-right: 15px;
        top: 100%;

        .page-content {
            background: $white;
            padding: 2em !important;
            border: 1px solid $gray-lighter2;
            margin: 0 7.5px;

            .card {
                background-color: transparent;
                border: 0;
                padding-right: 0;
                h3 {
                    text-transform: uppercase;
                    font-size: 1.2rem;
                    border-bottom: 1px solid $gray-light;
                    padding-bottom: 0.5rem;
                    font-weight: bold;
                    color: $gray-dark;
                }
                .form-check {
                    padding-left: 0;

                    .form-check-input {
                        &:checked + label {
                            background: $gray-lighter2;
                            color: $gray-darker;
                            padding-left: 1.75rem;
                            &::before {
                                font-family: "FontAwesome";
                                content: "\f046";
                                opacity: 1;
                            }
                        }

                        &:disabled + label {
                            cursor: not-allowed;
                        }
                    }

                    .form-check-label {
                        padding: 0.5rem 0;
                        color: $gray-dark;
                        display: block;
                        transition: all 0.1s ease;
                        &:hover {
                            background: $gray-lightest;
                            color: $gray-dark;
                            transition: all 0.1s ease;
                            padding-left: 0.5rem;
                        }
                        &::before {
                            font-family: "FontAwesome";
                            content: "\f046";
                            opacity: 0;
                            position: absolute;
                            left: 0.5rem;
                        }
                    }
                    .filter-badge {
                        position: absolute;
                        display: inline-block;
                        font-size: 0.85rem;
                        background: $gray-lighter2;
                        padding: 0.1rem 0;
                        color: $gray;
                        right: 0.5rem;
                        text-align: center;
                        min-width: 3em;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(sm) {
        .list-sort,
        .list-itemsperpage {
            display: inline-block;
        }

        .filter-toggle {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            right: 0.5em;
        }
    }
    @include media-breakpoint-up(md) {
        #filterCollapse {
            .page-content {
                .card {
                    padding-right: 2em;
                }
            }
        }
    }
}

.sidebar-categories {
    background: $white;
    font-family: $font-family-heading;

    .category-title {
        background: #efefef;
        padding: 1.2rem 2rem;
        text-transform: uppercase;
    }
    ul {
        @include reset-list();
        li {
            &.first-level {
                display: none;
            }
            &.first-level.active {
                display: list-item;
            }
            a {
                display: block;
                color: $gray-dark;
                padding: 0.75rem 15px;
                font-size: 1rem;
                cursor: pointer;
                &:hover {
                    color: $gray-darker;
                    background: $gray-lightest;
                }
            }
            ul {
                display: none;
            }
            &.active {
                & > ul {
                    display: block;
                }
                ul {
                    li {
                        a {
                            padding-left: 1.5rem;
                        }
                        ul li {
                            a {
                                padding-left: 3.5rem;
                            }
                            ul li a {
                                padding-left: 5.5rem;
                            }
                        }
                    }
                }
            }
            &.active {
                & > a {
                    background: $gray-lighter2;
                }
            }
        }
    }
}
