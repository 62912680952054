.input-group-v{

	.input-unit-v1{
		margin-top: -1px;
		margin-bottom: 0;
		border-radius: 0;
	}
	
	&>*:first-child{
		margin-top: 0;
		border-top-right-radius: $border-radius;
		border-top-left-radius: $border-radius;
	}

	&>*:last-child{
		border-bottom-right-radius: $border-radius;
		border-bottom-left-radius: $border-radius;
	}
	

}

