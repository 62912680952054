.widget-image-box
{
    @include media-breakpoint-only(xs) {
        padding-bottom: 0 !important;
        min-height: 10rem;
    }

    > img
    {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.img-cover
        {
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
    }

    > .widget-inner
    {
        padding: 1.5rem;
        display: flex;

        .widget-caption
        {
            transition: 300ms all ease;

            h2, .h2
            {
                margin: 0;
                font-size: 1.75rem;
            }
        }
    }

    &.widget-image-box-fullwidth
    {
        > .widget-inner
        {   
            @include media-breakpoint-only(xs) {
                position: relative;
                min-height: 12rem;
                padding-top: 2rem;
            }

            .widget-caption
            {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 1rem;

                h2, .h2
                {
                    text-align: center;
                    width: 100%;
                    text-transform: uppercase;

                    @include media-breakpoint-up(sm)
                    {
                        font-size: 3rem;
                    }
                    @include media-breakpoint-up(lg)
                    {
                        font-size: 4rem;
                    }
                }
            }
        }
    }

    &.widget-image-box-inline-caption
    {
        > .widget-inner
        {
            align-items: flex-end;

            @include media-breakpoint-only(xs) {
                position: relative;
                padding-top: 14%;
            }

            .widget-caption
            {
                padding: 1.5rem;
            }
        }
    }

    &.widget-image-box-block-caption
    {
        > .widget-inner
        {
            @include media-breakpoint-only(xs) {
                position: relative;
                padding: 25% 0 0 0;
            }

            .widget-caption
            {
                @include media-breakpoint-only(xs) {
                    position: relative;
                    width: 100%;
                }

                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 1rem;
            }
        }
    }

    &.widget-image-box-no-caption
    {
        > .widget-inner
        {
            .widget-caption
            {
                display: none;
            }
        }
    }


    &.widget-primary
    {
        .widget-caption
        {
            @include widget-bg-variant( rgba($primary, .8) );
        }

        a[href].widget-inner:hover .widget-caption
        {
            @include widget-bg-variant( rgba($primary, .9) );
        }
    }

    &.widget-secondary
    {
        .widget-caption
        {
            @include widget-bg-variant( rgba($secondary, .8) );
        }

        a[href].widget-inner:hover .widget-caption
        {
            @include widget-bg-variant( rgba($secondary, .9) );
        }
    }

    &.widget-success
    {
        .widget-caption
        {
            @include widget-bg-variant( rgba($success, .8) );
        }

        a[href].widget-inner:hover .widget-caption
        {
            @include widget-bg-variant( rgba($success, .9) );
        }
    }

    &.widget-info
    {
        .widget-caption
        {
            @include widget-bg-variant( rgba($info, .8) );
        }

        a[href].widget-inner:hover .widget-caption
        {
            @include widget-bg-variant( rgba($info, .9) );
        }
    }

    &.widget-warning
    {
        .widget-caption
        {
            @include widget-bg-variant( rgba($warning, .8) );
        }

        a[href].widget-inner:hover .widget-caption
        {
            @include widget-bg-variant( rgba($warning, .9) );
        }
    }

    &.widget-danger
    {
        .widget-caption
        {
            @include widget-bg-variant( rgba($danger, .8) );
        }

        a[href].widget-inner:hover .widget-caption
        {
            @include widget-bg-variant( rgba($danger, .9) );
        }
    }
}

.widget-grid .widget-image-box-fullwidth .widget-inner .widget-caption h2 {
    font-size: 2rem;
}

/* Compatibility for IE */
/* display img with optimal aspect ratio */
html.ie {
    .widget-image-box .img-cover {
        min-height: auto;
        min-width: auto;
    }
}