.bs4-progress
{
    display: flex;
    height: .75rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem;
}

.bs4-progress-bar
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    transition: width .6s ease;
    @include context-bg($primary);
}
