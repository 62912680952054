.order-return {
	& .order-return-item {
		border-bottom: 1px solid $gray-lighter;
		padding: 10px 0px;
		overflow: hidden;
	
		& > div.row {
			display:flex;
			height: 100px;
	
			& .order-return-image-container {
				max-width: 100%;
				width: 100%;
				position: relative;
	
				@include img-contain();
			}
	
			& > .order-return-texts {
				align-self: center;
			}
	
			& > .order-return-container {
				display:flex;
			}
		}

		.order-flex {
			display: flex;

			& .input-group {
				align-self: center;
				max-width: 7rem
			}

			& .max-counter-slash {
				align-self: center;
				font-size: 1.3rem;
				margin: 0px 10px
			}

			& .max-counter-return {
				align-self: center;
				font-size: 1.3rem
			}
		}
	
		.order-return-name {
			overflow: hidden;
			position: relative;
			line-height: 1.2em;
			max-height: 4.8em;
			margin-right: -1em;
			padding-right: 1em;
		
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
		
			@media screen and (-webkit-min-device-pixel-ratio:0) {
				&:after {
					bottom: 0;
				}
			}
		}
	}

	input[type='number'] {
		border-left: 1px solid darkgrey;
	}
	
	@include media-breakpoint-up(md) {
		.order-return-name {
			font-size: 1.15rem;
		}
	}
}

.order-return-history-item
{
    display: flex;
    flex-direction: row;
    
    .order-return-history-image-container
    {
        height: 80px;
    }
    
    .col-md-8
    {
        display: flex;
        align-items: center;
    }
}