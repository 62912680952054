$color: $gray-dark;
$background: white;
$active-background: white;
$border-color: $gray-lighter;
$border-active: $gray-light;
$border-width: 1px;

.input-unit.textarea
{
    background-color: #fff;

    & > textarea
    {
        overflow-y: auto;
        white-space: normal;
    }

    & > label
    {
        background-color: #fff;
        opacity: 0.9;
    }
}

.input-unit
{
    position: relative;
    width: 100%;
    margin-bottom: $grid-gutter-width/2;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    overflow: hidden;

    &.media-xs-d
    {
        @include media-breakpoint-down(xs)
        {
            margin-bottom: $grid-gutter-width/2 !important;
        }
    }

    &.no-bottom
    {
        margin-bottom: 0px;
    }

    input[type="date"]
    {
        max-height: 38px;
    }

    input[type], textarea,
    input:not([type="checkbox"]),
    input:not([type="radio"]),
    .input-unit-preview
    {

        color: $color;
        background-color: $background;
        border: none;
        box-shadow: none; //inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
        line-height: 1.2em !important;
        padding: 1.3rem 1rem .3rem 1rem;
        width: 100%;
        font-size: 1em;
        // text-align: right;
        outline: none !important;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;

        transition: all .2s;

        &:focus,
        &.filled
        {
            color: $color;
            background-color: $active-background;
            border-color: $border-active;
        }

        &.disabled,
        &:disabled
        {
            background-color: $gray-lightest;
            border-color: $gray-lighter;
            cursor: not-allowed;

            & ~ label
            {
                color: $gray-lighter;
                cursor: not-allowed;
            }
        }

        & + .symbol
        {
            color: $gray;
            position: absolute;
            top: $border-width;
            right: $border-width;

            transition: all .2s;
        }

        &.disabled,
        &:disabled
        {
            & + .symbol
            {
                color: $gray;
            }

            & + label
            {
                color: $gray-light !important;
            }
        }
    }

    label,
    .input-unit-label
    {
        font-size: .65em;
        line-height: 1em !important;
        text-transform: uppercase;
        letter-spacing: .1px;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        width: 100%;
        padding: 0.35rem 1rem 0;
        margin-bottom: 0;
        color: $gray-light;

        position: absolute;
        top: 0;
        left: 0;
        transition: color .15s;
        cursor: pointer;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    i
    {
        font-style: normal;
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $gray-lightest;
        color: $gray-lighter;
        line-height: 1.2em;
        width: 1.2em;
        text-align: center;
        cursor: pointer;
        border-bottom-right-radius: 2px;
    }

    &.error,
    .input-feedback-container.has-error > &
    {
        z-index: $zIndex-1;
        border-color: $danger;

        input
        {
            color: $danger;
        }

        label
        {
            color: $danger;
        }

        i
        {
            background-color: $danger;
            color: white;
        }

    }

    &.success,
    .input-feedback-container.has-success > &
    {
        z-index: $zIndex-1;
        border-color: $success;

        input
        {
            color: $success;
        }

        label
        {
            color: $success;
        }

        i
        {
            background-color: $success;
            color: white;
        }

    }

    .custom-select
    {
        padding: 1.2rem 2rem .4rem 1rem;
        background-position: calc(100% - 1rem) 1.2rem;
    }
}

.file-input
{
    height: 38px;
    cursor: pointer;

    > input[type="file"]
    {
        width: 0;
        height: 0;
        padding: 0;
        visibility: hidden;
    }

    > .input-unit-preview
    {
        height: 38px;
    }

    > .input-unit-btn
    {
        content: "";
        position: absolute;
        width: 38px;
        height: 38px;
        background-color: $border-color;
        display: block;
        right: 0;
        top: 0;

        i
        {
            margin: 10px;
            width: 18px;
            height: 18px;
            font-weight: 300;
            background-color: $border-color;
            color: $body-color;
        }
    }

    &.success .input-unit-btn,
    &.success .input-unit-btn i
    {
        background-color: $success;
        color: #fff;
    }

    &.error .input-unit-btn,
    &.error .input-unit-btn i
    {
        background-color: $danger;
        color: #fff;
    }
}

.input-unit.multiple
{
    display: flex;
    flex-flow: row nowrap;

    & > input
    {
        width: 70%;
    }

    & > select
    {
        width: 30%;
        border-left: $border-width solid $border-color;
    }
}

.remove-disabled-coloring
{
    &:disabled
    {
        background-color: initial !important;
    }
}


.form-check
{
    transition: all .3s ease;
    cursor: pointer;
    margin-bottom: .75rem;

    &.error
    {
        background: $danger !important;
        color: $white !important;
        border-radius: .1rem;
        transition: all .3s ease;
        padding: .5em 1.75rem;

        a
        {
            color: $white;
            &:hover
            {
                color: $gray-lighter2;
            }
        }

        & .text-muted
        {
            color: $white !important;
        }
    }
}

.input-feedback-container
{
    margin-bottom: $grid-gutter-width/2;

    .input-unit
    {
        margin-bottom: 0;
    }

    .feedback
    {
        font-size: .8rem;
        text-align: right;
        display: none;
    }

    .feedback-error
    {
        color: $danger;
    }

    .feedback-success
    {
        color: $success;
    }

    &.has-error .feedback-error,
    &.has-success .feedback-success
    {
        display: block;
    }
}