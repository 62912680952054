.cmp-address-list{

	.items{
		position: relative;

		//TODO add class to this div and set this rule by the class
		&>div:first-child{
			@extend .card;
			margin-bottom: 0;
		}
	}

	.dropdown-toggle{

		&:after{
		    position: absolute;
				top: 80%;
    		right: 1.5rem;
		    transform: translateY(-50%);
		}
	}

	.items.open{
		.dropdown-toggle{
			border-bottom: none;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.dropdown-menu{
		width: 100%;
		margin: 0;
		padding: 0;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		border-top: none;
		border: 1px solid $gray-lighter;
	}

	ul{
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		box-shadow: $box-shadow;
		max-height: 500px;
		overflow-x: none;
		overflow-y: auto;
	}

	.add-item{
		position: absolute;
		
		@include media-breakpoint-down(xs)
		{
			top: 0;
			right: 0;

			& button {
				min-width: auto !important;
				width: 50px;
				height: 74px;
			}
		}

		@include media-breakpoint-up(sm)
		{
			top: 1em;
			right: 1em;
		}
	}

	.item{
		padding: 0;
		border-bottom: 1px solid $gray-lighter;
		position:relative;
		input{ display: none; }

		input:checked{

			& + .item-inner{
				box-shadow: inset 0 0 0 0.1rem $primary;
				color: $black;
				background-color: #d9edf7;
			}

			& + .item-inner .item-edit .fa:hover{
				color: white;
			}
			& + .item-controls .fa:hover{
				color: white;
				cursor: pointer;
			}
		}

		&:last-of-type{
			border-bottom: none;
		}
	}


	.item-inner{
		display: flex;
		flex-direction: row;
    	cursor: pointer;
		padding: 1em;
		margin-bottom: 0;

		& .item-controls {
			@include media-breakpoint-down(md) {
				width: 83px;
			}

			@include media-breakpoint-up(md) {
				width: 75px;
			}
		}
	}

	.item-edit{
		& .btn {
			width: 2.2rem;
			padding-right: 6px;
			height: 2.2rem;
		}
		.fa{
			color: #fff;
			margin-top: 1px;
			transition: all .2s ease-in-out;
			
    	}
	}
	.item-remove {
		& .btn {
			width: 2.2rem;
			padding-right: 9px;
			height: 2.2rem;
		}
	    .fa {
	    	color: #fff;
			transition: all .2s ease-in-out;
			margin-top: -1px;
	    }
	}

	.item-content{
		display: inline-block;
	    // width: calc( 100% - 4em );
	    width: 100%;
	    flex: 1 1 auto;

		strong, span{
			display: block;
		}
	}

	@include media-breakpoint-up(sm){

	}

	@include media-breakpoint-up(md){
		.items{
			display: inline-block;
			vertical-align: middle;
			// width: calc( 100% - 5em );
			width:100%;
		}

	}

	@include media-breakpoint-up(lg){

	}
}
.checkout {
	header{
		h4 {
    	line-height: 2.5rem;
		}
	}
}
