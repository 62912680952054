// Searchbar in widget
.top-bar {
    .cmp-search-box {
        position: relative;
        top: auto; 
        right: auto;
        left: auto;
        .search-box-inner {
            margin: 5px 0;
            @include media-breakpoint-down(md) {
                margin: 5px 5px;
            }
        }
    }
}

// Searchbar in standard-header
div:not(.top-bar) {
    > .page-header {
        > div {
            > .cmp-search-box {
                top: 30px;
                z-index: 1070;
                position: fixed;
                right: 0;
                left: 0;

                @include media-breakpoint-up(lg) {
                    .search-box-inner {
                        margin: 5px auto;
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        } 
    }
}

.cmp-search-box
{
    background-color: $top-bar-bg;
    width: 100%;
    
    .search-box-inner
    {
        display: flex;
        flex-flow: row nowrap;
        margin: 5px 15px;
        position: relative;
        max-width: 1200px;
        margin: 0 auto;
        @include media-breakpoint-down(md) {
			margin: 5px 5px;
		}
    }
    
    @at-root .search-box-shadow-frame
    {
        display: flex;
        flex: 100;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
        position: relative;

        .autocomplete-suggestions
        {
            position: absolute;
            box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
            background: #ffffff;
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
            z-index: 1070;
            max-height: 310px;
            width: 100%;
            top: 100%;

            & > .autocomplete-suggestion
            {
                padding: 5px 15px;
                white-space: nowrap;
                overflow: hidden;
                cursor: pointer;
                display: flex;

                &:hover
                {
                    background: $gray-lighter;
                }

                &.autocomplete-selected
                {
                    background: $gray-lighter;
                }

                & > .autocomplete-item-name
                {
                    align-self: center;
                    color: $gray-dark;
                }

                & > .autocomplete-image-container
                {
                    width: 40px;
                    height: 40px;
                    position: relative;
                    margin-right: 5px;

                    & > .autocomplete-image
                    {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateY(-50%) translateX(-50%);
                        max-height: 40px;
                        max-width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }
    .search-input
    {
        flex: 1 0 70%;
        display: inline-block;
        vertical-align: top;
        font-size: 1.3rem;
        line-height: 1;
        border: none;
        padding: .4rem 1rem;
        outline: none;
    }

    .search-submit
    {
        display: inline-block;
        vertical-align: top;
        font-size: 1.3rem;
        line-height: 1;
        background-color: $gray-200;
        padding: .4rem 1rem;
        color: $primary;
        border: none;
        transition: all 300ms;
        outline: none;
        &:hover
        {
            color: darken($primary, 20%);
        }
    }
}