.card{
	
	.item-edit{
		position: absolute;
		top: 1rem;
		right: 1rem;
		font-size: 1.5rem;
		line-height: 1.5rem;
		
		.fa{
    		width: 1em;
		    height: 1em;
		    color: $gray-light;
		    transition: all .2s ease-in-out;

		    &:hover{
				color: $primary;
				border-color: $primary;
	    	}
    	}
	}
}