.live-shopping-details
{
    position: relative;
    padding: 0;
    margin: 0;

    .live-shopping-item-name
    {
        padding: $live-shopping-padding-x;
        margin: 0;

        > a
        {
            color: $gray;
            display: block;
            font-weight: normal;
            line-height: 1.5em;
            position: relative;
            text-align: center;
        }
    }

    .live-shopping-countdown
    {
        padding: $live-shopping-padding-y $live-shopping-padding-x;
        padding-top: .6rem;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;

        .live-shopping-countdown-separator
        {
            margin-bottom: .5rem;
            margin-top: 0;
        }

        .live-shopping-countdown-heading
        {
            text-align: center;
            margin-bottom: 5px;
        }
        
        .live-shopping-countdown-thread-container
        {
            display: flex;

            &:not(:last-child)
            {
                margin-bottom: .5rem;
            }
    
            .live-shopping-countdown-thread
            {
                margin-left: auto;
                margin-right: auto;
                text-align: center;

                .live-shopping-countdown-thread-number
                {
                    line-height: 1.1;
                    font-size: 2rem;
                    font-weight: bold;
                }
            }
        }
    }

    .live-shopping-prices
    {
        padding: 0.5rem 1rem 0.8rem 1rem;

        .live-shopping-prices-inner
        {
            display: grid;
            grid-template-areas: 
            "top"
            "bottom";

            .live-shopping-prices-rebate
            {
                grid-area: bottom;
            }

            .live-shopping-prices-container
            {
                grid-area: top;
                margin-left: 0;

                .live-shopping-price
                {
                    line-height: 1.1;
                    font-size: 2rem;
                }
            }
        }

        .live-shopping-prices-additional-info
        {
            font-size: $small-font-size;
            font-weight: normal;
            text-align: left;
            margin-top: .25rem;

            a
            {
                color: inherit;
                font-weight: bold;
            }
        }
    }
    .live-shopping-progress
    {
        &:not(:last-child)
        {
            margin-bottom: .5rem;
        }

        .live-shopping-progress-heading
        {
            text-align: center;

            &:not(:last-child)
            {
                margin-bottom: .5rem;
            }   
        }
    }
}

.live-shopping-add-to-basket
{
    padding-left: $live-shopping-padding-x;
    padding-right: $live-shopping-padding-x;
}

@for $i from 10 to -1 {
    .progress-#{$i * 10}
    {
        background-color: mix($success, $danger, $i * 10);
    }
}
