.widget-item-list
{
    .widget-inner
    {
        padding-left: 0;
        padding-right: 0;
    }

    .widget-caption
    {
        padding: 1rem 1.5rem;
        display: flex;
        align-items: center;

        h2, .h2
        {
            flex: 1;
            padding-top: .2rem;
            margin: 0;
            text-transform: uppercase;
            font-size: 1.75rem;
        }
    }

    &.widget-primary .widget-caption
    {
        @include widget-bg-variant($primary);
    }

    &.widget-secondary .widget-caption
    {
        @include widget-bg-variant($secondary);
    }

    &.widget-success .widget-caption
    {
        @include widget-bg-variant($success);
    }

    &.widget-info .widget-caption
    {
        @include widget-bg-variant($info);
    }

    &.widget-warning .widget-caption
    {
        @include widget-bg-variant($warning);
    }

    &.widget-danger .widget-caption
    {
        @include widget-bg-variant($danger);
    }
}