.page-infos{
	display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .product-count{
    	flex: 1;
    }
}

.product-list {
	list-style-type: none;
	padding: 0;
	margin-bottom: 0;

	display: flex;
    flex-flow: row wrap;
    
    category-item {
        background: $white;
        display: block;
        height: 145px;
        margin-bottom: 15px;
        width: 100%;

        @include media-breakpoint-up(sm) {
            margin-bottom: 30px;
            padding-bottom: calc(100% + 100px);
            height: unset;
        }
    }
}

.lazy{
    display:none;
}

@include media-breakpoint-up(sm){
    .product-list{
        li{
            display: flex;
        }
    }

    .product-list.grid{

    	li{
            box-shadow: none;
            display: flex;
    	}

    }
}

@include media-breakpoint-up(md){
    .product-list.grid{

        li{
            display: flex;
        }
    }
}

@include media-breakpoint-up(lg){
      .product-list.grid{

            li{
                display: flex;
            }
      }

}

.item-list-frame.loading:not(.blur-effect) > ul {
  display: none
}

.parallax-img-container {
    height: auto;
    max-width: none;
    position: relative;
    width: 100%;

    .parallax-text-wrapper {
        @include media-breakpoint-up(xl){
            margin-left: -15px;
            margin-right: -15px;
        }
    }
        
    .parallax-text-container {
        position: inherit;

        h1 {
            margin-bottom: 0;
            word-wrap: break-word;
        }

        .category-description {
            word-wrap: break-word;
        }
    }

    .parallax-img-container-inner[style^='background-image'] {
        height: 300px;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: top center;

        @include media-breakpoint-up(sm) {
            height: 350px;
        }    
        @include media-breakpoint-up(md) {
            height: 400px;
        }

        .parallax-text-container {
            max-width: 100%;
            position: absolute;
            bottom: 0;
            padding-right: 30px;            
    
            @include media-breakpoint-up(xl) {
                max-width: 1200px;
            }
    
            h1 {
                color: $category-text-color;
                font-size: 300%;
                text-shadow: 0 0 0.1em rgba(0, 0, 0, 0.75);
                bottom: 0;
                width: 100%;
                margin-bottom: .5rem;
                letter-spacing: 1px;
    
                @include media-breakpoint-up(sm) {
                    font-size: 400%;
                    margin: 0 0 1rem;
                }    
                @include media-breakpoint-up(md) {
                    font-size: 500%;
                }
            }
    
            .category-description {
                font-size: 1rem;
                line-height: 1.25;
                color: $white;
                text-shadow: 0 0 0.2em rgba(0,0,0,.75);
                letter-spacing: 1px;
    
                @include media-breakpoint-up(sm){
                    font-size: 1.25rem;
                }    
                @include media-breakpoint-up(md) {
                    font-size: 2rem;
                }
            }
        }
    }
}
