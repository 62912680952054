//navigation scss

@include media-breakpoint-up(md){
	.wrapper-main {
		& > nav.container-max {
			padding-left:0;
			padding-right:0;
		    z-index: 1000;
		    display: block;
		    width: 100%;
		    max-width: 100%;
		    background:$breadcrumb-bg;
		}
		.breadcrumbs {
			background: $breadcrumb-bg;
		    z-index: 1000;

			&.unfixed {
				z-index: -1;
			}
		}
		.breadcrumb{
			max-width: 1200px;
		    margin: 0 auto;
		    background: transparent;
		    padding: .5rem 15px;
		}
	}
	
}
@include media-breakpoint-up(lg){
	.wrapper-main {
		.breadcrumb{
			@include media-breakpoint-up(xl){
				padding: .5rem 0;
			}
		}
	}
}
